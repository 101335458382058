const defaultState = {
    //loading
    // allLoading : true,
    // //update Settings
    // exceptConfId : 0,

    // //expand Settings

    // loading: false,


    // //base Settings
    // checkForm : false,//booking提交时置为true,进行base校验
    // checkFormError : false,//base校验失败时置为true
    // confType : 1,//会议类型
    // confName : '',//会议描述
    // confRemark : '',//会议备注

    // record : false,
    // live : false,

    userAllChecked : false,//user列表allCheck checkbox状态
    userData : [],//用户数据
    selectedUserData: new Map(),
    userLoading : false,
    userSearchText : '',//user查询-模糊匹配文字
    userSearchOrg : 0,//user查询-组织架构
    userPagination : {//userTable分页信息
        showQuickJumper : false,
        total : 0,
        current : 1,
        size : 'small',
        position : 'bottom',
        showSizeChanger : true,
        pageSize : 10,
        pageSizeOptions : ['10','20', '50', '100']
    },

    reUserAllChecked : false,//user列表allCheck checkbox状态
    reUserData : [],//用户数据
    reUserSearchText : '',//user查询-模糊匹配文字
    reUserPagination : {//userTable分页信息
        showQuickJumper : false,
        total : 0,
        current : 1,
        size : 'small',
        position : 'bottom',
        showSizeChanger : true,
        pageSize : 10,
        pageSizeOptions : ['10','20', '50', '100']
    },

    roomAllChecked : false,//room列表与user基本类似
    roomData : [],
    roomLoading : false,
    roomSearchText : '',
    roomSearchOrg : 0,
    roomPagination : {
        showQuickJumper : false,
        total : 0,
        current : 1,
        size : 'small',
        position : 'bottom',
        showSizeChanger : true,
        // pageSize : 30
        pageSizeOptions : ['10','20', '50', '100']
    },


    // //selectedList Settings
    ptMap : new Map(),//已选与会者合集
    // prepareMap : new Map(),
    // ptMapShow : [true, true, true, true, false],  //类型查询控制 room, user ,outer, device, isCloud
    // ptMapSearchText : '',              //查询模糊匹配
    // selectedAllCheckedLength : 0,    //全选长度
    // selectedCheckedLength : 0,       //已选长度
    // selectedIndeterminate : false,   //全选按钮indeterminate
    // masterKey : null, //主与会者MD5 KEY
    // deviceParamList : new Map(),//呼叫参数列表



    // //advance Settings
    // confParamAll : [],
    // confParam : null,
    // defaultCallParamList : [],
    // defaultCallParam : 0,
    // recallSettings : {
    //     recallTimeSpan : 30,
    //     recallMaxCount : 1,
    //     validate : 'success'
    // },
    // advanceParams : [],
    // advanceModalVisible : false,
    // advanceConfig : null,


    // confParamMap : new Map(),
    selectedConfParamId : null,
    configStr : null,
    // confParamId : null,

    tcParamMap : new Map(),
    // selectedTcParamId : null,
    // tcParamId : null,


    // //organization Settings
    // selectedOrg : new Set(),
    // mainOrg : "",

    passwordLength : 6,
    // accountType : 0,
    needPassword : false,
    // userPopVisible : false,
    // userPopMap : null,
    // spinning : true,
    // publicParams : [],
    // personalParams : [],
    // realParams : null,
    // subParams : null,
    // fkTemplateconfig : null,
    // bookingModalVisible : false,
    // confInvite : null,
    // participantsModalVisible : false,
    // bookingData : null,
    // updatePeriodicModalShow : false,


    schedule : undefined,
    confPeriodic : '0'
};

export const bookingReducer = (state = defaultState, action) => {
    switch (action.type) {

        case 'setBookingReducer' : {
            state = {
                ...state,
                ...action.payload
            };
            break;
        }

        case 'resetBookingReducer' : {
            state = {
                ...defaultState
            };
            break;
        }



        default : {

        }

    }
    return state;
};