import './login.less';
import React from 'react';
import { connect } from "react-redux";
import { Button, Input, Form, Checkbox } from 'antd';
import Tooltip from "antd/es/tooltip";
import { ApiUtil, SetToken } from "../../utils/ApiUtils";
import { encrypt } from "../../utils/EncryptUtils";
import Bg from '../../assets/zh_icon/light.png'
import InfoBg from '../../assets/zh_icon/login_cb.png'
import dayjs from "dayjs";
import { withRouter } from 'react-router';


const inputCss = { fontSize: '14px', height: 36, paddingLeft: 15, fontWeight: 200};
// const faIconCss = {color : '#00000066'};

const weekText = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
const rememberPasswordExpire = 1000 * 60 * 60 * 24 * 7; // 7天
class Login extends React.Component {

    constructor(props){
        super(props);

        const today = dayjs()

        this.state = {
            authCode : '',
            platform : /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'H5' : 'WEB',
            today : today.format('YYYY年MM月DD日'),
            weekDay : weekText[today.day()],
            rememberPassword : false,
            setRememberPassword : false,
            showRememberPasswordTip: false
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        this.refreshCode();
        this.setState({
            rememberPassword: JSON.parse(localStorage.getItem('rememberPassword'))
        })
        this.formRef.current.setFields([{
            name: "loginName",
            value: localStorage.getItem('username'),
        },{
            name: "loginPassword",
            value: localStorage.getExpire('password')
        }])
    }

    login = async () => {

        this.formRef.current.validateFields().then(async (values) => {
            let resp;
            try{
                resp = await ApiUtil.post('/login',
                    {
                        loginName: values.loginName,
                        password: encrypt(values.loginPassword),
                        captcha: !values.authCode ? '' : values.authCode,
                        platform: this.state.platform,
                        captchaKey : this.state.captchaKey
                    }
                );
            }catch(e){
                return
            }
            console.log(resp)
            if(resp.status !== 200) return;
            if (resp.data.code === 200) {
                
                localStorage.setItem("username", values.loginName)
                if (this.state.rememberPassword) {
                    localStorage.setExpire("password", values.loginPassword, rememberPasswordExpire)
                } else {
                    localStorage.setItem("password", "")
                }

                SetToken(resp.data.data.token)
                localStorage.setItem('sid', resp.data.data.token)

                let host = resp.data.data.socketIONode.host; 
                let port = resp.data.data.socketIONode.port; 
                let scheme = (!!resp.data.data.socketIONode.schemes && resp.data.data.socketIONode.schemes.length>0)?resp.data.data.socketIONode.schemes[0]:'http';
                if(!!host){
                    let url = scheme + host
                    if (port) {
                        url += ':' + port
                    }
                    console.log('获取的socketIO地址',url);
                    localStorage.setItem('socket', url)
                }

                let whoamiData = await ApiUtil.get("/check");
                console.log(whoamiData)
                let myData = whoamiData.data.data
                myData.tenantUuid = myData.tenant.uuid;
                this.props.setPortalReducer({
                    token: resp.data.data.token,
                    myData : myData
                });
                this.props.history.push('/')
            }else {
                let data = resp.data;
                this.formRef.current.setFields([{
                    name: data.message === '验证码错误' ? "authCode" : "loginPassword",
                    value: null,
                    errors: [data.message]
                }]);
                this.refreshCode();
                // switch (data.message) {
                //     case '验证码错误': {
                //         this.formRef.current.setFields([{
                //             name: "authCode",
                //             value: null,
                //             errors: [data.data]
                //         }]);
                //         this.refreshCode();
                //         break;
                //     }
                //     case '密码不正确': {
                //         this.formRef.current.setFields([{
                //             name: "loginPassword",
                //             value: null,
                //             errors: [data.data]
                //         }]);
                //         this.refreshCode();
                //         this.formRef.current.setFields([{
                //             name: "authCode",
                //             value: null,
                //             errors: ['']
                //         }]);
                //         break;
                //     }
                //     case '该用户不存在': {
                //         this.formRef.current.setFields([{
                //             name: "loginName",
                //             value: null,
                //             errors: [data.data]
                //         }]);
                //         this.refreshCode();
                //         this.formRef.current.setFields([{
                //             name: "authCode",
                //             value: null,
                //             errors: ['']
                //         }]);
                //         break;
                //     }
                //     default: {
                //         this.formRef.current.setFields([{
                //             name: "loginName",
                //             value: null,
                //             errors: [" "]
                //         }, {
                //             name: "loginPassword",
                //             value: null,
                //             errors: [data.data]
                //         }]);
                //     }
                // }
            }
        }).catch(errorInfo => {
            console.error(errorInfo);
        });

        //    window.location.href = "/";
    }

    refreshCode = async () => {
        try{
            let ret = await ApiUtil.get('/captcha');
            console.log(ret)
            if(ret.status === 200){
                this.setState({
                    authCode: ret.data.data.base64Image,
                    captchaKey : ret.data.data.captchaKey
                });
            }
        }catch(e){
        }
        
    }

    toggleRememberPassword = (e) => {
        if (e.target.checked && !localStorage.getItem("firstRememberPasswordTip")) {
            localStorage.setItem("firstRememberPasswordTip", true)
            this.setState({
                showRememberPasswordTip: true
            })
        }

        this.setState({
            rememberPassword: e.target.checked
        })
        localStorage.setItem('rememberPassword', JSON.stringify(e.target.checked))
    }

    render() {

        Storage.prototype.setExpire=(key, value, expire) =>{
            let obj={
                data:value,
                time:Date.now(),
                expire:expire
            };
            localStorage.setItem(key,JSON.stringify(obj));
        }

        Storage.prototype.getExpire= key =>{
            let val =localStorage.getItem(key);
            if(!val){
                return val;
            }
            val =JSON.parse(val);
            if(Date.now()-val.time>val.expire){
                localStorage.removeItem(key);
                return null;
            }
            return val.data;
        }

        return (
            <div style={{background: '#ebecee', overflowY: 'hidden', height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img
                    onDragStart={(e) => {
                        e.preventDefault();
                    }}
                    alt=""
                    style={{ width: '12%', top : 0, position: 'absolute' }}
                    src={Bg}
                />
                {/* {!!window.company && <img
                    onDragStart={(e) => {
                        e.preventDefault();
                    }}
                    alt=""
                    style={{  position: 'absolute', top : 20, left : 20, width : 200 }}
                    src={ImageLogo}
                />} */}
                <div style={{  position: 'absolute', top : 20, right : 40 }}>
                    <span style={{padding : 3, fontWeight : 500}}>{this.state.today}</span>
                    <span style={{padding : '3px 5px', borderRadius : 5, background : '#FDD470', color : 'white', fontWeight : 500}}>{this.state.weekDay}</span>
                </div>
                <div id={'login-panel'} style={{ width: 360, height: 480, position: 'relative', display: 'flex', borderRadius: 15, boxShadow: 'rgb(141 140 140 / 15%) 0px 0px 15px 5px', margin : 0 }}>
                    <img
                        onDragStart={(e) => {
                            e.preventDefault();
                        }}
                        alt=""
                        style={{ width: '100%', position: 'absolute', objectFit: 'fill', borderRadius : 10 }}
                        src={InfoBg}
                    />
                   
                    <div style={{ flex: 1, backgroundColor: 'white', borderRadius: 10, display : 'flex', flexDirection : 'column' }}>

                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft : 30, height: 100, color : 'white',  zIndex : 1}}>
                            <div style={{ display: 'flex', flexDirection : 'column'}}>
                                <div style={{ fontSize: 18, fontWeight: 500, letterSpacing : 2}}>{window.company}</div>
                                <div style={{ fontSize: 12, fontWeight: 200, letterSpacing : 1, paddingTop : 5}}>Hi,欢迎登录{window.company}</div>
                            </div>

                            
                        </div>
                        {/* <div className="login-tr"></div> */}
                        <div
                            style={{ margin: 20, flex : 1, position : 'relative' }}
                            id={'login-card'}
                        >
                            <Form style={{ width: '100%' }} ref={this.formRef} autoComplete="off" layout='vertical'>
                                <Form.Item
                                    id={'form-login-name'}
                                    label={<div style={{fontSize : 12, color : '#999999'}}>用户名</div>}
                                    name="loginName"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value) {
                                                    return Promise.reject('请输入用户名');
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        className="login-input"
                                        style={inputCss}
                                        //prefix={<FontAwesomeIcon icon={faUser} style={faIconCss}/>}
                                        placeholder={"用户名"}
                                        onPressEnter={this.login}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={<div style={{fontSize : 12, color : '#999999'}}>密码</div>}
                                    name="loginPassword"
                                    // rules={[
                                    //     ({ getFieldValue }) => ({
                                    //         validator(rule, value) {
                                    //             if (!value) {
                                    //                 return Promise.reject();
                                    //             } else {
                                    //                 return Promise.resolve();
                                    //             }
                                    //         },
                                    //     }),
                                    // ]}
                                >
                                    <Input.Password
                                        visibilityToggle={true}
                                        className="login-input"
                                        style={inputCss}
                                        //prefix={<FontAwesomeIcon icon={faKey} style={faIconCss}/>}
                                        placeholder={"密码"}
                                        onPressEnter={this.login}
                                    />
                                </Form.Item>

                                <Form.Item
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value) {
                                                    return Promise.reject('请输入验证码');
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                        }),
                                    ]}
                                    name="authCode"
                                    label={<div style={{fontSize : 12, color : '#999999'}}>验证码</div>}
                                >
                                    <Input
                                        className="login-input"
                                        style={inputCss}
                                        //prefix={<FontAwesomeIcon icon={faShieldAlt} style={faIconCss}/>}
                                        placeholder={"验证码"}
                                        onPressEnter={this.login}
                                       
                                        suffix={
                                            <Tooltip title={'点击刷新'}>
                                                <img
                                                    src={this.state.authCode}
                                                    alt={'验证码'}
                                                    style={{ height: 28, width: 72 }}
                                                    onClick={this.refreshCode}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </Form.Item>

                            </Form>

                            <div style={{color : '#888888'}}>{window.LOGIN_TIP}</div>


                            <Tooltip title="请勿在公共电脑上使用此功能，密码有效期为7天" color='red' placement='right' open={this.state.showRememberPasswordTip}>
                                <Checkbox
                                    checked={this.state.rememberPassword}
                                    onChange={this.toggleRememberPassword}>
                                    记住密码
                                </Checkbox>
                            </Tooltip>
                            
                            <Button
                                    size={"large"}
                                    type={"primary"}
                                    style={{ width: '100%', height: 36, fontSize : 12, bottom : 0, top: 10 }}
                                    onClick={this.login}
                                >
                                登录
                            </Button>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispachToProps = (dispatch) => {
    return {
        setPortalReducer: (target) => {
            dispatch({
                type: "setPortalReducer",
                payload: target
            });
        },
    }
};

export default connect(null , mapDispachToProps)(withRouter(Login));
