import React from 'react';
import { connect } from "react-redux";
import { Layout, Dropdown, Menu,  message, Avatar, Modal, Button } from "antd";
import {LogoutOutlined, CaretDownOutlined, LoadingOutlined, ClusterOutlined, UserOutlined} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { logout } from '../../common/commonActions';
import ImageLogo from '../../assets/Image_logo_haifutong.png'
const { Header } = Layout;
class PortalHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hide: props.hide,
            exiting: false,
        };
    }
    
    

    render() {
        let logoUrl = this.props.portal.myData.tenant.logoUrl

        return (

            <Header
                style={{ display: this.state.hide ? 'none' : 'flex', background: '#fff', padding: "0px", zIndex: 1, boxShadow: 'rgb(0 0 0 / 0.12) 0px 1px 4px 1px' }}
                id={'portal-header'}
            >
                <Modal
                    visible={this.state.exitModal}
                    title={'确认退出？'}
                    bodyStyle={{padding : 0}}
                    onCancel={() => {
                        this.setState({
                            exitModal : false
                        })
                    }}
                    footer={[
                        <Button type="primary" style={{float : 'left'}} key="back" onClick={() => {
                            this.setState({
                                exitModal : false
                            })
                        }}>取消</Button>,
                        <Button key="submit" type="danger" loading={this.state.exiting} onClick={async () => {
                            try{
                                this.setState({
                                    ...this.state,
                                    exiting : true
                                })
                                await logout();
                                // window.location.href = window.MY_PREFIX + '/#/';
                                window.localStorage.removeItem('sid');
                                this.props.history.push('/login')
                                // window.location.reload();
                                
                            }catch(e) {
                                console.log(e);
                            }
                        }}>确认</Button>
                    ]}
                >
                </Modal>
                {!!window.company && <img
                    onDragStart={(e) => {
                        e.preventDefault();
                    }}
                    alt=""
                    style={{height : 40, position: 'absolute', top : 4, left : 10 }}
                    src={logoUrl}
                />}
                <div style={{ paddingLeft: 250, display: 'flex', alignItems: 'center', flex: 1, width : 50, fontWeight : 400, fontSize : 16, letterSpacing : 1 }}>
                    会议管理平台
                </div>
                <div>
                <Dropdown
                    placement="bottomRight"
                    overlay={
                        <Menu>
                            {/* <Menu.Item
                                key='1'
                                icon={<ClusterOutlined />}
                                onClick={() => {
                                    message.info('敬请期待...')
                                    // window.open(window.origin + '/admin');
                                }}
                            >
                                管理平台
                            </Menu.Item> */}
                            <Menu.Item
                                key='2'
                                icon={<UserOutlined />}
                                onClick={() => {
                                    window.location.href = window.origin + '/#/personal';
                                }}
                            >
                                个人中心
                            </Menu.Item>
                            <Menu.Item
                                key='3'
                                icon={!this.state.exiting ? <LogoutOutlined /> : <LoadingOutlined  spin />}
                                onClick={async () => {
                                    this.setState({
                                        exitModal : true
                                    })
                                }}
                            >
                                退出登录
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <div style={{float : 'right', cursor : 'pointer', height : '100%', display : 'flex', alignItems :'center', paddingRight : 10, color :'#4a4a4a'}}>
                        
                        <span style={{paddingRight : 10, fontSize : 16}}>
                            {this.props.portal.myData.name}
                        </span>
                        <Avatar size={24} icon={<UserOutlined />} />
                        <span style={{paddingLeft : 5, }}>
                            <CaretDownOutlined style={{fontSize : 12}}/>
                        </span>
                    </div>
                </Dropdown>
                </div>
            </Header>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        portal : state.portal
    }
};

const mapDispachToProps = (dispatch) => {
    return {

        setPortalReducer : (target) => {
            dispatch ({
                type : "setPortalReducer",
                payload : target
            });
        },

   
    }
};

export default connect(mapStateToProps, mapDispachToProps)(withRouter(PortalHeader));

